<template>
  <div class="page-content">
    <div class="row">
      <div class="col-md-12" v-if="steps_list.length > 0">
        <div class="card ml-2 mb-3">
          <div class="card-body d-flex justify-content-between">
            <div class="d-flex">
              <button class="btn btn-outline-primary" @click="addCard">
                <font-awesome icon="plus"/> Add Leads
              </button>
              <div class="row ml-4">
                <div class="col-4 col-sm-4">
                  <el-select v-model="search.filter_type" placeholder="Filter type" style="width:100%">
                    <el-option label="Lead" value="lead"></el-option>
                    <el-option label="Contact" value="contact"></el-option>
                    <el-option label="Assignee" value="user"></el-option>
                  </el-select>
                </div>
                <div class="col-8 col-sm-8">
                  <form class="search-form" @submit.prevent="loadData()">
                    <div class="input-group border rounded-sm">
                      <div class="input-group-prepend">
                        <div class="input-group-text border-0 rounded-sm">
                          <FeatherIcon type="search" class="icon-md cursor-pointer" />
                        </div>
                      </div>
                      <input v-model="search.search" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <h4 class="float-right" style="font-size: 1.3rem;">Rp. {{ total_amount?.toLocaleString() }}</h4>
          </div>
        </div>
        <div class="d-flex justify-content-around table-responsive">
          <el-card class="box-card mx-2 overflow-auto" style="min-width: 30%; max-height: 500px;"
          v-for="(v,i) in listSteps()" :key="i" v-loading="loading.leads[v.key]">
            <div slot="header" class="clearfix" @drop="onDrop($event, v.key)" @dragover.prevent @dragenter.prevent>
              <div class="d-flex justify-content-between">
                <span><b>{{ v.label }}</b> ({{ v.count || 0 }})</span>
              </div>
              <p>Rp. {{ v.amount.toLocaleString() }}</p>
            </div>
            <div @drop="onDrop($event, v.key)" @dragover.prevent @dragenter.prevent>
              <div class="card card-list border-primary p-1 mt-2 cursor-pointer" v-for="(dt, idt) in v.data" :key="dt.id"
              draggable @dragstart="startDrag($event, v.key, idt)">
                <div class="card-list-body p-2">
                  <div class="">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex align-items-top">
                        <!-- <div class="d-flex mr-2 mt-1">
                          <FeatherIcon style="margin-left: -5px" type="more-vertical" class="link-icon feather-16"/>
                          <FeatherIcon style="margin-left: -10px" type="more-vertical" class="link-icon feather-16"/>
                        </div> -->
                        <el-tooltip class="item" effect="dark" :content="`${dt.user?.name}${dt.participant}`" placement="top">
                          <div class="mt-1 mr-1" :data-initials-contact="initials(dt.user?.name)"/>
                        </el-tooltip>
                        <!-- <el-tooltip class="item" v-for="user in dt.participant" effect="dark" :content="`${user}`" placement="top">
                          <div class="mt-1 ml-n4" :data-initials-contact="initials(user)"/>
                        </el-tooltip> -->
                        <div>
                          <p><b>{{ dt.name }}</b></p>
                          <p>{{ dt.contact_name }} ({{ dt.contact.phone_number }})</p>
                          <p>{{ dt.amount ? `Rp. ${dt.amount_str}` : '-' }}</p>
                        </div>
                      </div>
                      <div class="align-items-top" v-if="(i+1) == steps_list.length">
                        <el-dropdown trigger="click" @command="completeLeads($event, dt)">
                          <span class="el-dropdown-link">
                            <i class="el-icon-arrow-down el-icon--right"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item class="text-primary" command="won"><b>Won</b></el-dropdown-item>
                            <el-dropdown-item class="text-danger" command="lost"><b>Lost</b></el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mt-2">
                      <div class="d-flex">
                        <el-tooltip class="item" effect="dark" content="Detail" placement="top">
                          <button class="btn btn-outline-primary mr-1 btn-xs mt-2 p-1" @click="detailCard(dt)">
                            <font-awesome icon="eye"/>
                          </button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                          <button class="btn btn-outline-warning mr-1 btn-xs mt-2 p-1" @click="editCard(dt)">
                            <font-awesome icon="edit"/>
                          </button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                          <button class="btn btn-outline-danger btn-xs mt-2 p-1" @click="deleteLeads(dt)">
                            <font-awesome icon="trash"/>
                          </button>
                        </el-tooltip>
                      </div>
                      <div class="d-flex align-items-center mt-2">
                        <span class="mr-2" style="font-size: 0.75rem;">{{ dt.due_date_str }}</span>
                        <b-badge :variant="dt.color_date" class="p-1">{{ dt.label_date }}</b-badge>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <el-empty v-if="v.data.length == 0" description="No data"></el-empty>
              <div>
                <el-link @click="loadListStep(v)" type="info" class="my-3 float-right" v-if="v.count_exist < v.count">Load More...</el-link>
              </div>
            </div>
          </el-card>
        </div>
      </div>
      <div class="col-md-12" v-else>
        <el-empty description="You have no step status for your leads. Let's add first!">
          <router-link class="btn btn-primary" to="/leads/settings">Let's go!</router-link>
        </el-empty>
      </div>
    </div>
    <!-- <b-modal v-model="modal.add" title="Add New Leads" hide-footer> -->
    <el-dialog
      title="Add New Leads"
      :visible.sync="modal.add"
      width="50%">
      <el-form label-width="150px" @reset="closeModal" class="full-width mb-5">
        <el-form-item label="Lead name">
          <el-input v-model="form.name" placeholder="Please enter project name"></el-input>
        </el-form-item>
        <el-form-item label="Assign to">
          <el-select v-model="form.sales_id" filterable remote reserve-keyword :remote-method="searchUser"
          placeholder="Please enter a keyword" :loading="loading.get_users" style="width: 100%">
            <el-option
              v-for="item in filter_users"
              :key="item.id"
              :label="item.text"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Contact">
          <el-select v-model="form.contact_id" filterable remote reserve-keyword :remote-method="searchContact"
          placeholder="Please enter a keyword" :loading="loading.get_contacts" style="width: 100%">
            <el-option
              v-for="item in filter_contacts"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Projection amount">
          <el-input v-model="form.amount" placeholder="Please enter project amount (optional)"
          :formatter="(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
          :parser="(value) => value.replace(/\Rp\s?|(,*)/g, '')"></el-input>
        </el-form-item>
        <el-form-item label="Due Date">
          <el-date-picker
            v-model="form.due_date"
            type="date"
            width="100%"
            placeholder="Pick date (optional)"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item class="float-right">
          <el-button size="small" type="primary" @click="createLeads" :loading="loading.update_leads">Submit</el-button>
          <el-button size="small" @click="closeModal">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- </b-modal> -->
    <el-dialog
      title="Update Leads"
      :visible.sync="modal.edit"
      width="50%">
      <el-form label-width="150px" @reset="closeModal" class="full-width mb-5">
        <el-form-item label="Name">
          <el-input v-model="form.name" placeholder="Please enter project name"></el-input>
        </el-form-item>
        <el-form-item label="Assign to">
          <el-select v-model="form.sales_id" filterable remote reserve-keyword :remote-method="searchUser"
          placeholder="Please enter a keyword" :loading="loading.get_users" style="width: 100%">
            <el-option
              v-for="item in filter_users"
              :key="item.id"
              :label="item.text"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Contact">
          <el-select disabled v-model="form.contact_id" filterable remote reserve-keyword :remote-method="searchContact"
          placeholder="Please enter a keyword" :loading="loading.get_contacts" style="width: 100%">
            <el-option
              v-for="item in filter_contacts"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Amount">
          <el-input v-model="form.amount" placeholder="Please enter project amount"
          :formatter="(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
          :parser="(value) => value.replace(/\Rp\s?|(,*)/g, '')"></el-input>
        </el-form-item>
        <el-form-item label="Due Date">
          <el-date-picker
            v-model="form.due_date"
            type="date"
            width="100%"
            placeholder="Pick date"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Team">
          <el-select v-model="form.sales_assigned_ids" multiple filterable remote reserve-keyword :remote-method="searchContributedUser"
          placeholder="Please enter a keyword" :loading="loading.get_users" style="width: 100%">
            <el-option
              v-for="item in filter_contributed_users"
              :key="item.id"
              :label="item.text"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="float-right">
          <el-button size="small" type="primary" @click="updateLeads" :loading="loading.update_leads">Submit</el-button>
          <el-button size="small" @click="closeModal">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="Complete Lead"
      :visible.sync="modal.action"
      width="25%">
      <el-form @reset="closeModal" class="full-width mb-5" size="lg">
        <h6 class="mb-2">Lead Name : {{ detail_card?.name }}</h6>
        <el-form-item label="Reason for losing lead">
          <el-radio-group v-model="form_action.reason" style="width: 100%">
            <el-radio v-for="item in rejected_reason" style="width: 100%"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="" v-if="form_action.reason == 'Others'">
          <el-input v-model="form_action.reason_detail" placeholder="Insert detail reasons"></el-input>
        </el-form-item>
        <el-form-item class="float-right mt-2">
          <el-button size="small" type="primary" @click="saveLost" :loading="loading.update_leads">Submit</el-button>
          <el-button size="small" @click="closeModalAction">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { upperFirst, isEmpty } from 'lodash';
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { required, numeric } from '@vuelidate/validators';
import popupErrorMessages from '@/library/popup-error-messages';
import leadsAPI from '../../../api/leads';
import usersAPI from '../../../api/users';

export default {
  name: 'LeadsBoard',
  metaInfo() {
    return {
      title: 'Leads Setting',
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        amount: {
          numeric,
        },
        contact_id: {
          required,
        },
        sales_id: {
          required,
        },
      },
    };
  },
  data() {
    return {
      loading: {
        get_leads: false,
        update_leads: false,
        create_leads: false,
        get_contacts: false,
        get_users: false,
        get_contributed_users: false,
        leads: {},
      },
      modal: {
        add: false,
        edit: false,
        action: false,
      },
      form: {
        name: '',
        amount: '',
        due_date: '',
        contact_id: '',
        sales_id: '',
        sales_assigned_ids: [],
      },
      form_action: {
        reason: '',
        reason_detail: '',
      },
      loaderStack: 0,
      loader: null,
      search: {
        search: '',
        filter_type: 'lead',
      },
      total_amount: 0,
      steps_list: [],
      card_list: [],
      filter_contacts: [],
      filter_users: [],
      filter_contributed_users: [],
      detail_card: {},
      id_card: '',
      rejected_reason: [
        'Insufficient budget',
        'Product does not fit need',
        'Not satisfied with conditions',
        'Bought from competitor',
        'Others',
      ],
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
    // const temp = [];
    /* eslint-disable no-plusplus */
    this.loadData();
    this.loadUsers();
  },
  methods: {
    initials(string) {
      return (string || '').split(' ').map((n) => n[0]).join('').slice(0, 2);
    },
    upperCaseFirst(string) {
      return upperFirst(string);
    },
    listSteps() {
      let step_arr = [];
      if (this.steps_list.length > 0) {
        step_arr = [...this.steps_list].sort((a, b) => a.order - b.order);
      }
      return step_arr;
    },
    async loadData() {
      this.showLoader();
      const response = await leadsAPI.get_steps({ workspace_id: this.activeWorkspace._id });
      this.hideLoader();
      await popupErrorMessages(response);
      this.total_amount = 0;
      if (response.data.steps) {
        this.steps_list = response.data.steps.map((v) => {
          v.data = [];
          v.count = 0;
          v.amount = 0;
          v.count_exist = 0;
          return v;
        });
        this.steps_list.forEach(async (dt) => {
          this.$set(this.loading.leads, dt.key, false);
          await this.loadListStep(dt);
        });
      } else {
        this.steps_list = [];
      }
    },
    async loadListStep(dt, total = null) {
      this.loading.leads[dt.key] = true;
      const idx = this.steps_list.findIndex((x) => x.key === dt.key);
      const offset = this.steps_list[idx].count_exist;
      const res = await leadsAPI.get_leads({
        workspace_id: this.activeWorkspace._id,
        step_key: dt.key,
        offset,
        search: this.search.search,
        filter_type: this.search.filter_type,
      });
      this.loading.leads[dt.key] = false;
      await popupErrorMessages(res);
      if (res.data.rows.length > 0) {
        const leads_data = res.data.rows.map((v) => {
          v.contact_name = v.contact?.display_name || v.contact?.name;
          v.participant = '';
          if (!isEmpty(v.user_contributed)) {
            const participant = v.user_contributed.map((x) => x.name).join(', ');
            v.participant = `, ${participant}`;
          }
          v.amount_str = v.amount ? parseInt(v.amount, 0).toLocaleString() : '0';
          v.due_date_str = v.due_date ? moment(v.due_date).format('DD MMM') : '';
          v.label_date = '';
          v.color_date = '';
          if (v.due_date) {
            if (moment(v.due_date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
              v.label_date = 'Today';
              v.color_date = 'warning';
            } else if (moment().format('YYYY-MM-DD') === moment(v.due_date).subtract(1, 'days').format('YYYY-MM-DD')) {
              v.label_date = 'Tomorrow';
              v.color_date = 'warning';
            } else if (moment().format('YYYY-MM-DD') === moment(v.due_date).subtract(7, 'days').format('YYYY-MM-DD')) {
              v.label_date = '1 week left';
              v.color_date = 'primary';
            } else if (moment(v.due_date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
              v.label_date = 'Overdue';
              v.color_date = 'danger';
            } else if (moment().format('YYYY-MM-DD') > moment(v.due_date).subtract(7, 'days').format('YYYY-MM-DD')) {
              const diffDays = moment(moment(v.due_date).format('YYYY-MM-DD')).endOf('day').fromNow();
              v.label_date = `${diffDays.replace('in ', '')} left`;
              v.color_date = 'primary';
            }
          }
          return v;
        });
        this.steps_list[idx].data.push(...leads_data);
        this.steps_list[idx].count = res.data.count;
        this.steps_list[idx].amount = res.data.total_amount || 0;
        this.steps_list[idx].count_exist += leads_data.length;
        if (total == null) {
          this.total_amount += parseInt(this.steps_list[idx].amount, 0);
        }
      }
      this.loading.leads[dt.key] = false;
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    closeModal() {
      this.form = {
        name: '',
        amount: '',
        due_date: '',
        contact_id: '',
      };
      this.modal.add = false;
      this.modal.edit = false;
    },
    addCard() {
      this.modal.add = true;
    },
    async editCard(dt) {
      await this.searchContact(dt.contact.phone_number);
      dt.user.text = `${dt.user.name} (${dt.user.email})`;
      this.filter_users = [dt.user];
      this.filter_contributed_users = [];
      if (dt.user_contributed.length > 0) {
        this.filter_contributed_users = dt.user_contributed.map((v) => {
          v.text = `${v.name} (${v.email})`;
          return v;
        });
      }
      this.id_card = dt.id;
      this.form = {
        name: dt.name,
        amount: dt.amount,
        due_date: dt.due_date,
        contact_id: dt.contact_id,
        sales_id: dt.sales_id,
        sales_assigned_ids: dt.sales_assigned_ids,
      };
      this.modal.edit = true;
    },
    detailCard(dt) {
      this.$router.push(`/leads/card/${dt.id}`);
    },
    completeLeads(command, dt) {
      if (command === 'won') {
        this.setWon(dt);
      } else if (command === 'lost') {
        this.setLost(dt);
      }
    },
    setWon(dt) {
      this.$confirm(`Are you sure want to complete ${dt.name} lead to Won?`, 'Complete Lead', {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            leadsAPI.complete_leads({
              workspace_id: this.activeWorkspace._id,
              id: dt.id,
              data: {
                status: 'won',
              },
            }).then(async (res) => {
              this.hideLoader();
              await popupErrorMessages(res);
              this.closeModal();
              this.$message({
                message: this.$t('leads.success.step.update'),
                type: 'success',
              });
              this.detail_card = {};
              this.$router.push('/leads/completed');
            }).catch(async () => {
              this.hideLoader();
            });
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    setLost(dt) {
      this.detail_card = dt;
      this.modal.action = true;
    },
    saveLost() {
      this.loading.update_leads = true;
      leadsAPI.complete_leads({
        workspace_id: this.activeWorkspace._id,
        id: this.detail_card.id,
        data: {
          reason: this.form_action,
          status: 'lost',
        },
      }).then(async (res) => {
        this.hideLoader();
        this.loading.update_leads = false;
        await popupErrorMessages(res);
        this.closeModalAction();
        this.$message({
          message: this.$t('leads.success.step.update'),
          type: 'success',
        });
        this.detail_card = {};
        this.$router.push('/leads/completed');
      }).catch(async () => {
        this.loading.update_leads = false;
        this.hideLoader();
      });
    },
    closeModalAction() {
      this.detail_card = {};
      this.form_action = {
        reason: '',
        detail_reason: '',
      };
      this.modal.action = false;
    },
    startDrag(evt, step, card_idx) {
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('step_from', step);
      evt.dataTransfer.setData('index_card', card_idx);
    },
    onDrop(evt, step_to) {
      const step_from = evt.dataTransfer.getData('step_from');
      if (step_to !== step_from) {
        this.loading.leads[step_to] = true;
        const index_card = parseInt(evt.dataTransfer.getData('index_card'), 0);
        const step_data_from = this.steps_list.find((v) => v.key === step_from);
        const step_data_to = this.steps_list.find((v) => v.key === step_to);
        const step_idx_from = parseInt(this.steps_list.findIndex((v) => v.key === step_from), 0);
        const step_idx_to = parseInt(this.steps_list.findIndex((v) => v.key === step_to), 0);
        const data_card = this.steps_list[step_idx_from].data[index_card];
        leadsAPI.update_step_leads({
          workspace_id: this.activeWorkspace._id,
          id: data_card.id,
          data: {
            step_from,
            step_to,
          },
        }).then(async (res) => {
          await popupErrorMessages(res);
          this.steps_list[step_idx_to].data = [];
          this.steps_list[step_idx_to].count = 0;
          this.steps_list[step_idx_to].count_exist = 0;
          this.steps_list[step_idx_from].data = [];
          this.steps_list[step_idx_from].count = 0;
          this.steps_list[step_idx_from].count_exist = 0;
          await this.loadListStep(step_data_from, 0);
          await this.loadListStep(step_data_to, 0);
          this.$message({
            message: this.$t('leads.success.step.update'),
            type: 'success',
          });
        }).catch(() => {});
        this.loading.leads[step_to] = false;
      }
    },
    createLeads() {
      this.loading.update_leads = true;
      this.showLoader();
      leadsAPI.create_leads({
        workspace_id: this.activeWorkspace._id,
        data: this.form,
      }).then(async (res) => {
        this.hideLoader();
        this.loading.update_leads = false;
        await popupErrorMessages(res);
        this.closeModal();
        this.$message({
          message: this.$t('leads.success.lead.add'),
          type: 'success',
        });
        this.loadData();
      }).catch(async () => {
        this.hideLoader();
        this.loading.update_leads = false;
      });
    },
    updateLeads() {
      this.loading.update_leads = true;
      this.showLoader();
      leadsAPI.update_leads({
        workspace_id: this.activeWorkspace._id,
        id: this.id_card,
        data: this.form,
      }).then(async (res) => {
        this.hideLoader();
        this.loading.update_leads = false;
        await popupErrorMessages(res);
        this.closeModal();
        this.$message({
          message: this.$t('leads.success.lead.update'),
          type: 'success',
        });
        this.loadData();
      }).catch(async () => {
        this.hideLoader();
        this.loading.update_leads = false;
      });
    },
    deleteLeads(dt) {
      this.$confirm(`Are you sure want to delete ${dt.name} lead?`, 'Delete Lead', {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            leadsAPI.delete_leads({
              workspace_id: this.activeWorkspace._id,
              id: dt.id,
            }).then(async (res) => {
              this.hideLoader();
              await popupErrorMessages(res);
              this.closeModal();
              this.$message({
                message: this.$t('leads.success.lead.delete'),
                type: 'success',
              });
              this.loadData();
            }).catch(async () => {
              this.hideLoader();
            });
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    async searchContact(keyword) {
      this.filter_contacts = [];
      if (keyword?.length > 2) {
        this.loading.get_contacts = true;
        await leadsAPI.searchContacts({
          workspace_id: this.activeWorkspace._id,
          keyword,
        }).then((res) => {
          this.filter_contacts = res.data.map((v) => {
            const x = {
              id: v.id,
              label: `${v.display_name || v.name} (${v.phone_number})`,
            };
            return x;
          });
        }).catch((err) => {
          console.log(err);
        });
        this.loading.get_contacts = false;
      }
    },
    loadUsers() {
      this.searchUser();
    },
    async searchUser(keyword = '') {
      this.filter_users = [];
      this.loading.get_users = true;
      await usersAPI.getList(1, keyword).then((res) => {
        this.filter_users = res.data.rows.map((v) => {
          const x = {
            id: v.id,
            text: `${v.name} (${v.email})`,
          };
          return x;
        });
      }).catch((err) => {
        console.log(err);
      });
      this.loading.get_users = false;
    },
    async searchContributedUser(keyword = '') {
      this.filter_contributed_users = [];
      this.loading.get_contributed_users = true;
      await usersAPI.getList(1, keyword).then((res) => {
        this.filter_contributed_users = res.data.rows.map((v) => {
          const x = {
            id: v.id,
            text: `${v.name} (${v.email})`,
          };
          return x;
        });
      }).catch((err) => {
        console.log(err);
      });
      this.loading.get_contributed_users = false;
    },
  },
};
</script>

<style lang="scss">
  .feather-16 {
    width: 14px;
    height: 14px;
  }
  [data-initials-contact]:before {
    background: #8CC8ED;
    outline-color: #00000000;
    color: white;
    opacity: 1;
    content: attr(data-initials-contact);
    display: inline-block;
    font-weight: bold;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 0.2em;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
  }
</style>
